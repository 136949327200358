import React, { useEffect, useState } from "react";
import { Divider, Modal, Spin, Switch } from "antd";
import {
  DC_REPLENISHMENT_EXCEL_COLUMN,
  REPLENISHMENT_DATA_TYPE,
} from "../../util/columns";
import { useDispatch } from "react-redux";
import { fetchReplenishmentList, MODULE_KEY } from "./action";
import TableFilter from "../common/TableFilter/TableFilter";
import Label from "../common/Label/Label";
import {
  replenishmentApprove,
  replenishmentRefresh,
  updateOrderQty,
} from "./saga";
import { useMsal } from "@azure/msal-react";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../util/notifications";
import Button from "../common/Button/Button";
import GenericTable from "../common/Table/GenericTable";
import filterColumns from "./filterColumns";
import VendorReplenishment from "./VendorReplenishment";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import styled from "styled-components";
import { getUserName } from "../common/UserDetails";
const ExcelJS = require("exceljs");

function Replenishment() {
  const userName = getUserName();
  const [isPendingApprovalOnly, setIsPendingApprovalOnly] = useState(true);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<REPLENISHMENT_DATA_TYPE[]>([]);
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [vendorName, setVendorName] = useState([]);
  const [sameVendorName, setSameVendorName] = useState([]);
  const [sameVendor, setSameVendor] = useState(false);
  const [exportFile, setExportFile] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [pOSum, setPOSum] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  let filterValues = filterColumns();
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };
  const triggerModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setTrigger(true);
  };
  const approvalModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    let vendorData: any = [];
    if (selectedRow.length > 0) {
      selectedRow?.map((item: any) => {
        selectedRow?.map((ele: any, index: any) => {
          if (item.VendorName !== ele.VendorName) {
            vendorData.push(ele.VendorName);
            setSameVendor(true);
          }
        });
      });
      setSameVendorName(vendorData);
      if (
        vendorData.length <= 0 ||
        selectedRow.every(
          (val: any, i: any, arr: any[]) => val.VendorName === arr[0].VendorName
        )
      ) {
        setApproveModal(true);
      }
    }
  };
  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: REPLENISHMENT_DATA_TYPE[]
    ) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      return { disabled: record.ApprovalStatus === "Approved" };
    },
    clearSelection: () => {
      setSelectedRow([]);
    },
    type: "checkbox",
  };
  const approvehandler = async (approve: boolean) => {
    setLoader(true);
    setApproveModal(false);
    setRejectModal(false);
    const data = {
      idList: selectedRow.map((row: REPLENISHMENT_DATA_TYPE) => row.Id),
      isApproved: approve,
      modifiedBy: userName,
    };
    try {
      const res = await replenishmentApprove(data);
      if (res) {
        setSelectedRow([]);
        showSuccessNotification(res);
        rowSelection.clearSelection();
        dispatch(
          fetchReplenishmentList({
            isPendingApprovalOnly,
            vendor: [],
            UserName: userName,
          })
        );
      } else {
        showErrorNotification(res.error.messages);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    }
    setLoader(false);
  };
  useEffect(() => {
    dispatch(
      fetchReplenishmentList({
        isPendingApprovalOnly,
        vendor: vendorName ? vendorName : [],
        UserName: userName,
      })
    );
  }, [isPendingApprovalOnly, vendorName]);

  useEffect(() => {
    if (filterValues?.data) {
      setPOSum(handlePOSum());
      setTotalQuantity(handleTotalQuantity());
    }
  }, [filterValues?.data]);

  useEffect(() => {
    if (filteredData) {
      setPOSum(handlePOSum());
      setTotalQuantity(handleTotalQuantity());
    }
  }, [filteredData]);

  const handlePOSum = () => {
    let totalSum = 0;
    const data =
      filteredData?.length > 0
        ? filteredData
        : filteredData[0] !== "none"
        ? filterValues?.data
        : [];
    data?.map((item: any) => {
      totalSum += item.ExtCost;
    });
    return totalSum;
  };

  const handleTotalQuantity = () => {
    let totalQuantity = 0;
    const data =
      filteredData?.length > 0
        ? filteredData
        : filteredData[0] !== "none"
        ? filterValues?.data
        : [];
    data?.map((item: any) => {
      totalQuantity += item.OverrideOrderQuantity;
    });
    return totalQuantity;
  };

  const handleRefresh = async () => {
    setLoader(true);
    try {
      const newObj: { VendorName: any }[] = [];
      const vendors =
        vendorName.length > 0 ? vendorName : filterValues.vendorName;
      vendors.map((item: any) => {
        newObj.push({ VendorName: item.value ? item.value : item });
      });
      const res = await replenishmentRefresh({
        VendorList: newObj,
        UserName: userName,
      });
      if (res) {
        showSuccessNotification(res);
        setOpen(false);
        setLoader(false);
        setTrigger(false);
        dispatch(
          fetchReplenishmentList({
            isPendingApprovalOnly,
            vendor: [],
            UserName: userName,
          })
        );
      } else {
        showErrorNotification(res.messages);
        setOpen(false);
        setTrigger(false);
        setLoader(false);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
      setOpen(false);
      setTrigger(false);
      setLoader(false);
    }
  };

  const exportExcelFile = async () => {
    setLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("DC Replenisment Sheet");
    //sheet.properties.defaultRowHeight = 25;

    sheet.columns = DC_REPLENISHMENT_EXCEL_COLUMN;
    const data =
      filteredData?.length > 0
        ? filteredData
        : filteredData[0] !== "none"
        ? filterValues?.data
        : [];
    try {
      if (data?.length > 0) {
        setExportFile(false);
        setLoader(false);
        let color;
        const promise = Promise.all(
          data?.map(async (item: any, index: number) => {
            const rowNumber = index + 2;
            sheet.addRow({
              SKU: item.SKU,
              FrameName: item.FrameName,
              VendorName: item.VendorName,
              Collection: item.Collection,
              OrderID: item.OrderID,
              OrderQuantity: item.OrderQuantity,
              OnHandQuantity: item.OnHandQuantity,
              BackOrderQuantity: item.BackOrderQuantity,
              MinQuantity: item.MinQuantity,
              MaxQuantity: item.MaxQuantity,
              OverrideOrderQuantity: item.OverrideOrderQuantity,
              UnitCost: item.UnitCost,
              ExtCost: item.ExtCost,
              OrderDate:
                item.OrderDate !== null
                  ? dayjs(item.OrderDate).format("YYYY-MM-DD")
                  : "",
              PreviousOrderId: item.PreviousOrderId,
              PreviousOrderDate:
                item.PreviousOrderDate !== null
                  ? dayjs(item.PreviousOrderDate).format("YYYY-MM-DD")
                  : "",
              ApprovalStatus: item.ApprovalStatus,
              CreatedDate:
                item.CreatedDate !== null
                  ? dayjs(item.CreatedDate).format("YYYY-MM-DD")
                  : "",
              ModifiedDate:
                item.ModifiedDate !== null
                  ? dayjs(item.ModifiedDate).format("YYYY-MM-DD")
                  : "",
              ModifiedBy: item.ModifiedBy,
            });
          })
        );

        promise.then(() => {
          workbook.xlsx.writeBuffer().then(function (res: BlobPart) {
            const blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "DC Replenishment-Report.csv";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          showSuccessNotification("Excel downloaded successfully");
        });
      } else {
        setExportFile(false);
        setLoader(false);
        showErrorNotification("No data available for the selected date");
      }
    } catch (error: any) {
      setExportFile(false);
      setLoader(false);
      showErrorNotification(error.response.data.error.messages[0]);
    }
  };

  const onSaveChanges = async (record: any, value: any, name: any) => {
    const payload = {
      id: record?.Id,
      OrderQuantity: value,
    };
    updateOrderQty(payload)
      .then((res: any) => {
        showSuccessNotification(res);
        dispatch(
          fetchReplenishmentList({
            isPendingApprovalOnly,
            vendor: [],
            UserName: userName,
          })
        );
      })
      .catch((error: any) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
  };

  return (
    <Spin spinning={loader}>
      <div>
        <Divider />
        <Breadcrumb>
          <span>Vendor Order</span>
        </Breadcrumb>
        <TableFilter>
          <div className="d-flex gap-2">
            <VendorReplenishment setVendorName={setVendorName} />
            <Button
              onClick={triggerModal}
              size="middle"
              disabled={!vendorName.length}
            >
              Manual Trigger
            </Button>
            <Label
              label={
                "Total PO Sum: " +
                pOSum.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              }
              className="flex-row align-items-center fs-6 gap-3"
            >
              <></>
            </Label>
            <Label
              label={"Total Units: " + totalQuantity}
              className="flex-row align-items-center fs-6 gap-3"
            >
              <></>
            </Label>
          </div>
          <div className="d-flex gap-2 justify-content-end">
            <Label
              label="Display Pending Orders only"
              className="flex-row align-items-center fs-6 gap-3"
            >
              <Switch
                size="small"
                checked={isPendingApprovalOnly}
                onChange={(flag) => {
                  setIsPendingApprovalOnly(flag);
                }}
              />
            </Label>
            <Button
              size="middle"
              icon={<DownloadOutlined />}
              ant-click-animating-without-extra-node="true"
              onClick={() => setExportFile(true)}
              disabled={filteredData[0] === "none"}
            >
              {" "}
              Export
            </Button>
            <Button onClick={closeModal} size="middle">
              Refresh
            </Button>
            <Button
              onClick={approvalModal}
              size="middle"
              disabled={loader || !selectedRow.length}
            >
              Approve
            </Button>
            <Button
              onClick={() => setRejectModal(true)}
              size="middle"
              danger={true}
              disabled={loader || !selectedRow.length}
            >
              Reject
            </Button>
          </div>
        </TableFilter>
        <GenericTable
          loading={loader}
          rowSelection={{
            selectedRowKeys: selectedRow.map((row: any) => row.Id),
            ...rowSelection,
          }}
          columns={filterValues.columns}
          rowkey="Id"
          pagination={{
            defaultPageSize: 500,
            showSizeChanger: true,
            pageSizeOptions: ["500", "1000", "2000", "5000"],
          }}
          moduleKey={MODULE_KEY}
          xScroll={2000}
          yScroll={"calc(90vh - 17.5em)"}
          filteredData={true}
          setFilteredData={setFilteredData}
          onSaveChanges={onSaveChanges}
        />

        <Modal
          title="Approve Confirmation"
          centered
          confirmLoading={loader}
          open={approveModal}
          onOk={() => approvehandler(true)}
          onCancel={() => setApproveModal(false)}
        >
          <span>
            Are you sure you want to Approve the request and replenish the
            selected items?
          </span>
        </Modal>
        <Modal
          title="Reject Confirmation"
          centered
          confirmLoading={loader}
          open={rejectModal}
          onOk={() => approvehandler(false)}
          onCancel={() => setRejectModal(false)}
        >
          <span>
            Are you sure you want to Reject the request and ignore the selected
            item replenishment?
          </span>
        </Modal>
        <Modal
          title="Refesh Confirmation"
          okText="Ok"
          centered
          confirmLoading={loader}
          style={{ top: 20 }}
          open={open}
          onOk={() => handleRefresh()}
          onCancel={() => setOpen(false)}
        >
          <span>
            Are you sure you want to refesh pending replenisment orders?
          </span>
        </Modal>
        <Modal
          title="Trigger Confirmation"
          okText="Ok"
          centered
          confirmLoading={loader}
          style={{ top: 20 }}
          open={trigger}
          onOk={() => handleRefresh()}
          onCancel={() => setTrigger(false)}
        >
          <span>
            Are you sure you wish to trigger a manual replenishment for the
            selected vendor(s)?
          </span>
        </Modal>
        <Modal
          title="Vendor Confirmation"
          okText="Ok"
          centered
          confirmLoading={loader}
          style={{ top: 20 }}
          open={sameVendor}
          onOk={(e) => setSameVendor(false)}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <span>
            Please ensure all orders are for the same vendor
            <br />
            Vendor Name : {[...new Set(sameVendorName)].join(", ")}
          </span>
        </Modal>
        <Modal
          title="Excel export Confirmation"
          okText="Ok"
          open={exportFile}
          centered
          confirmLoading={loader}
          style={{ top: 20 }}
          onOk={exportExcelFile}
          onCancel={() => setExportFile(false)}
        >
          <span>Are you sure you want to export this data?</span>
        </Modal>
      </div>
    </Spin>
  );
}
const StyledDiv = styled.div`
  color: #fff;
  position: absolute;
  left: 24%;
  font-size: 16px;
`;
export default Replenishment;
