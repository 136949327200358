import { Divider, Form, Modal, Popconfirm, Spin } from "antd";
import React, { useEffect, useState } from "react";
import GenericTable from "../common/Table/GenericTable";
import {
  ACCESSORY_REPLENISHMENT_COLUMN,
  ACCESSORY_REPLENISHMENT_EXCEL_COLUMNS,
} from "../../util/columns";
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Button from "../common/Button/Button";
import { insertAt } from "../../util/utils";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import TableFilter from "../common/TableFilter/TableFilter";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../util/notifications";
import { MODULE_KEY, fetchAccessoryReplenishment } from "./actions";
import AddPreferences from "./AddPreferences";
import EditPreferences from "./EditPreferences";
import { useDispatch } from "react-redux";
import { deleteAccessoryReplenishmentItems, getPreferencesData } from "./sagas";
import dayjs from "dayjs";
import filterColumns from "./filterColumns";
import { useMsal } from "@azure/msal-react";
const ExcelJS = require("exceljs");

function AccessoryReplenishment() {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [modalData, setModalData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [prefModal, setPrefModal] = useState(false);
  const [exportFile, setExportFile] = useState(false);
  const dispatch = useDispatch();
  const [upcCodes, setUpcCodes] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const { accounts }: any = useMsal();
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setExportFile(true);
  };
  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(true);
  };
  const prefEditModal = () => {
    setPrefModal(true);
  };
  const editDrawer = (record: any) => {
    let payload;
    let skuData: any = [];
    if (selectedRow.length > 0) {
      selectedRow?.map((item: any) => {
        selectedRow?.map((ele: any, index: any) => {
          if (item.SKU !== ele.SKU) {
            skuData.push(ele.SKU);
            prefEditModal();
          }
        });
      });
      setUpcCodes(skuData);
      if (
        skuData.length <= 0 ||
        selectedRow.every(
          (val: any, i: any, arr: any[]) => val.SKU === arr[0].SKU
        )
      ) {
        setShowEdit(true);
      }
      const ids = selectedRow.map((row: any) => row.Id);
      payload = ids;
    } else {
      payload = [record?.Id];
      setShowEdit(true);
    }
    const res = getPreferencesData(payload);
    res.then((response: any) => {
      setModalData(response);
    });
  };
  const deleteModal = (record: any) => {
    setOpen(true);
    setDeleteData(record?.Id);
  };
  let filterValues = filterColumns(editDrawer, deleteModal);
  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    let data: any = {
      offset: 0,
      limit: 5000,
    };
    dispatch(fetchAccessoryReplenishment([]));
  };
  const handleCancel = () => {
    handleData();
    setOpen(false);
    setSelectedRow([]);
    setShowEdit(false);
    form.resetFields();
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => {
      //return { disabled: "" }
    },
    clearSelection: () => {
      setSelectedRow([]);
    },
    selectedRowKeys: selectedRow.map((row: any) => row.Id),
    //hideSelectAll: true,
  };

  const handleDelete = async (e: any) => {
    setConfirmLoading(true);
    let payload: any = {
      DeletedBy: accounts?.[0]?.username,
    };
    try {
      if (selectedRow.length > 0) {
        const ids = selectedRow.map((row: any) => row.Id);
        payload.Ids = ids;
      } else {
        payload.Ids = [deleteData];
      }
      const res = await deleteAccessoryReplenishmentItems(payload);
      if (res) {
        showSuccessNotification(res);
        handleCancel();
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data);
    }
    setConfirmLoading(false);
  };
  const exportExcelFile = async () => {
    setConfirmLoading(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    //sheet.properties.defaultRowHeight = 25;

    sheet.columns = ACCESSORY_REPLENISHMENT_EXCEL_COLUMNS;
    const data =
      filteredData?.length > 0
        ? filteredData
        : filteredData[0] !== "none"
        ? filterValues?.data
        : [];
    try {
      if (data?.length > 0) {
        setExportFile(false);
        setConfirmLoading(false);
        let color;
        const promise = Promise.all(
          data?.map(async (item: any, index: number) => {
            const rowNumber = index + 2;
            sheet.addRow({
              ItemName: item.ItemName,
              SKU: item.SKU,
              ReplenishmentType: item.ReplenishmentType,
              ReplenishThreshold: item.ReplenishThreshold,
              OrderQuantity: item.OrderQuantity,
              CurrentOnHandQuantity: item.CurrentOnHandQuantity,
              IsStockedAtDC: item.IsStockedAtDC,
              LocationID: item.LocationID,
              IsConsiderBackorder: item.IsConsiderBackorder,
              SalesAfterLastReplenishDate: item.SalesAfterLastReplenishDate,
              InTransitQuantity: item.InTransitQuantity,
              LastReplenishmentDate:
                item.LastReplenishmentDate !== null
                  ? dayjs(item.LastReplenishmentDate).format("YYYY-MM-DD")
                  : "",
            });
          })
        );

        promise.then(() => {
          workbook.xlsx.writeBuffer().then(function (res: BlobPart) {
            const blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Replenishment-Preferences-Report.csv";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          showSuccessNotification("Excel downloaded successfully");
        });
      } else {
        setExportFile(false);
        setConfirmLoading(false);
        showErrorNotification("No data available for the selected date");
      }
    } catch (error: any) {
      setExportFile(false);
      setConfirmLoading(false);
      showErrorNotification(error.response.data.error.messages[0]);
    }
  };

  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Replenishment Preferences</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              icon={<DownloadOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={closeModal}
              disabled={filteredData[0] === "none"}
            >
              {" "}
              Export
            </Button>
            <Button
              size="middle"
              icon={<PlusOutlined />}
              style={{ marginRight: "10px" }}
              onClick={addDrawer}
              // disabled={!selectedRow.length}
              ant-click-animating-without-extra-node="true"
            >
              Add Preference
            </Button>
            <Button
              size="middle"
              icon={<EditOutlined />}
              style={{ marginRight: "10px" }}
              onClick={editDrawer}
              disabled={!selectedRow.length}
              ant-click-animating-without-extra-node="true"
            >
              Edit Preferences
            </Button>
            <Button
              size="middle"
              icon={<DeleteOutlined />}
              style={{ marginRight: "10px" }}
              onClick={deleteModal}
              disabled={!selectedRow.length}
              danger={true}
              ant-click-animating-without-extra-node="true"
            >
              Delete Preferences
            </Button>
          </div>
        </TableFilter>
        <GenericTable
          rowkey="Id"
          columns={filterValues.columns}
          rowSelection={rowSelection}
          moduleKey={MODULE_KEY}
          loading={confirmLoading}
          xScroll={1000}
          yScroll={"calc(90vh - 17.5em)"}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
          }}
          filteredData={true}
          setFilteredData={setFilteredData}
        />
        <AddPreferences
          visible={visible}
          setVisibleStatus={setVisible}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          onLoad={handleCancel}
          accounts={accounts}
        />
        <EditPreferences
          showEdit={showEdit}
          modalData={modalData}
          onLoad={handleCancel}
          accounts={accounts}
        />
      </Spin>
      <Modal
        title="Delete Product Confirmation"
        okText="Ok"
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={open}
        onOk={(e) => handleDelete(e)}
        onCancel={handleCancel}
      >
        <span>
          Are you sure you want to delete this accessory replenishment
          preference?
        </span>
      </Modal>
      <Modal
        title="Preference Confirmation"
        okText="Ok"
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={prefModal}
        onOk={(e) => setPrefModal(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <span>
          Please ensure all selected rows are for the same Product Code.
          <br />
          UPC Codes : {[...new Set(upcCodes)].join(", ")}
        </span>
      </Modal>
      <Modal
        title="Excel export Confirmation"
        okText="Ok"
        open={exportFile}
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        onOk={() => exportExcelFile()}
        onCancel={() => setExportFile(false)}
      >
        <span>Are you sure you want to export this data?</span>
      </Modal>
    </>
  );
}

export default AccessoryReplenishment;
