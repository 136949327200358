import React, { useEffect, useState } from "react";
import { Modal, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { cleanImportLayout } from "./actions";
import { usePDF } from "react-to-pdf";
import { ImportType } from "src/util/utils";
import Button from "src/components/common/Button/Button";

export const ValidationModal = ({
  visible,
  data,
  onCancel,
  importType,
}: any) => {
  const dispatch = useDispatch();
  const [errorMessages, setErrorMessages] = useState<any>([]);

  const handleCancel = () => {
    onCancel();
    dispatch(cleanImportLayout());
  };

  const { toPDF, targetRef } = usePDF({ filename: "Report.pdf" });

  useEffect(() => {
    if (
      importType === ImportType.PlanogramImport ||
      importType === ImportType.AssortmentImport ||
      importType === ImportType.ValidateAcuityImport
    )
      setErrorMessages(
        data?.ErrorMessages?.length > 0 ? data?.ErrorMessages?.split("|") : null
      );
    else setErrorMessages(data?.error?.messages[0]?.split("|"));
  }, [data]);

  return (
    <StyledValidationModal
      open={visible}
      title="Validation for failed import"
      onCancel={handleCancel}
      centered
      footer={
        <div className="d-flex justify-content-end mb-2">
          {(importType === ImportType.PlanogramImport ||
            importType === ImportType.ValidateAcuityImport) && (
            <Button
              type="primary"
              className="ghost-button"
              onClick={() => {
                toPDF();
                handleCancel();
              }}
            >
              PDF
            </Button>
          )}
          <Button
            type="primary"
            className="ghost-button"
            style={{marginLeft:"5px"}}
            onClick={handleCancel}
          >
            Ok
          </Button>
        </div>
      }
    >
      <div
        ref={targetRef}
        className="pdf-content"
        style={{ paddingTop: 20, paddingLeft: 30 }}
      >
        <div>
          {(importType === ImportType.PlanogramImport ||
            importType === ImportType.AssortmentImport ||
            importType === ImportType.ValidateAcuityImport) && (
            <div>
              <span>Total Rows in File : {data.Total_Count}</span>
              <br />
              <span>Number of Rows without Errors : {data.Success_Count}</span>
              <br />
              <span>Number of Rows with Errors : {data.Failed_Count}</span>
              <br />
              <br />
            </div>
          )}
          {errorMessages?.length > 0 && (
            <>
              <span>
                Row Numbers with Details of the Validation that Failed :
              </span>
              <br />
            </>
          )}
          {errorMessages?.map((item: any, index: any) => {
            return (
              <span key={index}>
                {`${index + 1}. ${item}`}
                <br />
              </span>
            );
          })}
        </div>
      </div>
    </StyledValidationModal>
  );
};

const StyledValidationModal = styled(Modal)`
  .ant-modal-content {
    width: 724px;
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  text-align: center;
`;
