import { Descriptions, Divider, Form, Input, Spin } from "antd";
import { MODULE_KEY } from "./actions";
import {
  DATE_FORMAT,
  VENDOR_ORDER_COLUMN,
  VENDOR_ORDER_DATA_TYPE,
} from "../../util/columns";
import GenericTable from "../common/Table/GenericTable";
import { useEffect, useRef, useState } from "react";
import {
  fetchOrderItems,
  fetchVendorOrderList,
  saveVendorOrderItems,
} from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../util/notifications";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import TableFilter from "../common/TableFilter/TableFilter";
import Button from "../common/Button/Button";
import DescriptionItems from "./DescriptionItems";
import { getUserName } from "../common/UserDetails";

function VendorOrders() {
  const userName = getUserName();
  const [show, setShow] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [updateData, setupdateData] = useState<any>([]);
  const [initialData, setInitialData] = useState<any>([]);
  const [invoiceNumber, setInvoiceNumber] = useState<any>("");
  const inputRef = useRef<any>();
  const [form] = Form.useForm();
  const [orderDetails, setOrderDetails] = useState({
    OrderNumber: null,
    OrderDate: "",
    VendorName: "",
  });
  const handleData = async () => {
    const items = fetchOrderItems({ orderNumber: orderNumber });
    items
      .then((res) => {
        const notEqualQuntity = res?.filter(
          (record: any) => record?.PreviousReceived !== record?.Quantity
        );
        const equalQuntity = res?.filter(
          (record: any) => record?.PreviousReceived === record?.Quantity
        );
        const data = [...notEqualQuntity, ...equalQuntity];
        setInitialData(data);
      })
      .catch((error) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
  };

  useEffect(() => {
    setInitialData([]);
    if (orderNumber !== "") {
      handleData();
    }
  }, [orderNumber]);

  const handleCancel = () => {
    setInitialData([]);
    handleData();
    setupdateData([]);
    form.resetFields();
    setInvoiceNumber("");
  };

  const handleOrderDetails = async (values: any) => {
    setConfirmLoading(true);
    setShow(false);
    setOrderNumber(values?.OrderNumber);
    try {
      const res = await fetchVendorOrderList({
        VendorOrderNumber: values?.OrderNumber,
        UserName: userName,
      });
      if (res) {
        form.resetFields();
        setOrderDetails(res);
        setShow(true);
      } else {
        showErrorNotification(res.error.message[0]);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    }
    setConfirmLoading(false);
  };

  const onSaveChanges = async (record: any, value: any, name: any) => {
    try {
      if (name === "Item Price") {
        record.Cost = value;
      } else if (name === "Received Qty") {
        record.Received = value;
      }
      let obj = {
        ID: record.ID,
        ItemID: record.ItemID,
        Received: record.Received,
        Cost: record.Cost,
      };
      const index = updateData.findIndex(
        (item: any) => record.ItemID === item.ItemID && item.Received !== "0"
      );
      if (index > -1) {
        updateData.splice(index, 1, {
          ...obj,
        });
      } else {
        if (record.Received !== "0") {
          updateData.push(obj);
        }
      }
      setupdateData([...updateData]);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const onSave = async () => {
    try {
      const row = (await form.validateFields()) as any;
      setConfirmLoading(true);
      let objs: { ID: any; ItemID: any; Received: any; Cost: any }[] = [];
      const filterRecords = initialData.filter((ele: any, ind: any) => {
        return !updateData.some((elem: any) => elem.ID === ele.ID);
      });
      filterRecords.map((item: any, ind: number) => {
        if (item.Received !== 0 && item.Received !== "0") {
          objs.push({
            ID: item.ID,
            ItemID: item.ItemID,
            Received: item.Received,
            Cost: item.Cost,
          });
        }
      });
      const vendorItem = [...objs, ...updateData];
      let payload = {
        vendorItem: vendorItem,
        VendorReceiptNumber: invoiceNumber,
        UserName: userName,
      };
      if (vendorItem.length > 0) {
        const res = await saveVendorOrderItems(payload);
        if (res) {
          setInitialData([]);
          handleData();
          setupdateData([]);
          showSuccessNotification(res);
        } else {
          showErrorNotification(res);
        }
      } else {
        showErrorNotification("Please enter at least one Receive Quantity");
      }
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
      } else if (error?.response?.data) {
        errMessage = error?.response?.data;
        showErrorNotification(errMessage);
      }
    }
    setConfirmLoading(false);
  };
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Vendor Receipt</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex">
            <Form name="order" size="large" onFinish={handleOrderDetails}>
              <div style={{ display: "flex" }}>
                <Form.Item
                  name="OrderNumber"
                  rules={[
                    {
                      required: true,
                      message: `Please enter order number`,
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Order number should be numeric`,
                    },
                  ]}
                >
                  {/* <Label label="Order Number"> */}
                  <Input placeholder="Order Number" ref={inputRef} />
                  {/* </Label> */}
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="middle"
                    style={{ marginLeft: 10 }}
                  >
                    Get Order Details
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
          {show && (
            <div className="d-flex justify-content-end">
              <Button
                size="middle"
                onClick={onSave}
                style={{ marginLeft: "10px" }}
                // disabled={!updateData.length}
              >
                Update
              </Button>
              <Button
                onClick={handleCancel}
                size="middle"
                style={{ marginLeft: "10px" }}
                danger
              >
                Cancel
              </Button>
            </div>
          )}
        </TableFilter>
        {show && (
          <>
            <DescriptionItems
              orderDetails={orderDetails}
              form={form}
              invoiceNumber={invoiceNumber}
              setInvoiceNumber={setInvoiceNumber}
            />
            <GenericTable
              rowkey="OrderNumber"
              columns={VENDOR_ORDER_COLUMN}
              dataSource={initialData}
              hideTotal={true}
              onSaveChanges={onSaveChanges}
              moduleKey={MODULE_KEY}
              xScroll={1000}
              yScroll={"calc(90vh - 17.5em)"}
              form={form}
              rowClassName={true}
            />
          </>
        )}
      </Spin>
    </>
  );
}

export default VendorOrders;
