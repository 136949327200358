import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import {
  ASSORTMENT_SKU_COLUMN,
  FRAME_COLLECTION_LISTING_COLUMN,
} from "src/util/columns";
import Button from "src/components/common/Button/Button";
import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ColorTag from "src/components/common/ColorTag/ColorTag";
import { ReactNode } from "react";
import { Tooltip } from "antd";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any,
  deleteModal?: ((record: any) => void) | any
) {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const vendors: any | null | undefined = [];
  const collection: any | null | undefined = [];
  const active: any | null | undefined = [];
  const frameType: any | null | undefined = [];
  data?.map((row: any) => {
    vendors.push({ text: row.Vendor, value: row.Vendor });
    collection.push({ text: row.Collection, value: row.Collection });
    frameType.push({
      text: row.FrameTypeDescription,
      value: row.FrameTypeDescription,
    });
    if (row.Active === true) {
      active.push({ text: "Active", value: row.Active });
    } else if (row.Active === false) {
      active.push({ text: "Inactive", value: row.Active });
    } else {
      active.push({ text: "NA", value: row.Active });
    }
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  let collectionName = collection.filter(
    (ele: any, ind: any) =>
      ind ===
      collection.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let activeStatus = active.filter(
    (ele: any, ind: any) =>
      ind === active.findIndex((elem: any) => elem.text === ele.text)
  );
  let FrameTypeDescription = frameType.filter(
    (ele: any, ind: any) =>
      ind ===
      frameType.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  const frameTypeColumns = {
    title: "Frame Type Description",
    dataIndex: "FrameTypeDescription",
    sorter: (
      a: { FrameTypeDescription: string },
      b: { FrameTypeDescription: any }
    ) => a.FrameTypeDescription?.localeCompare(b.FrameTypeDescription),
    sortDirections: ["ascend", "descend"],
    filters: FrameTypeDescription,
    onFilter: (value: any, record: { FrameTypeDescription: string | any[] }) =>
      record.FrameTypeDescription !== null &&
      record.FrameTypeDescription.indexOf(value) === 0,
    width: 125,
    align: "center",
  };
  const vendorColumns = {
    title: "Vendor Name",
    dataIndex: "Vendor",
    sorter: (a: { Vendor: string }, b: { Vendor: any }) =>
      a.Vendor?.localeCompare(b.Vendor),
    sortDirections: ["ascend", "descend"],
    filters: vendorName?.sort((a: any, b: any) => {
      return a.text.localeCompare(b.text);
    }),
    onFilter: (value: any, record: { Vendor: string | any[] }) =>
      record.Vendor !== null && record.Vendor.indexOf(value) === 0,
    filterSearch: true,
    width: 100,
    align: "center",
    render: (text: string) => {
      return text ? text : "";
    },
  };
  const collectionColumns = {
    title: "Collection Name",
    dataIndex: "Collection",
    sorter: (a: { Collection: string }, b: { Collection: any }) =>
      a.Collection?.localeCompare(b.Collection),
    sortDirections: ["ascend", "descend"],
    filters: collectionName?.sort((a: any, b: any) => {
      return a.text.localeCompare(b.text);
    }),
    onFilter: (value: any, record: { Collection: string | any[] }) =>
      record.Collection !== null && record.Collection.indexOf(value) === 0,
    filterSearch: true,
    width: 120,
    align: "center",
    render: (text: string) => {
      return text ? text : "";
    },
  };

  const activeColumn = {
    title: "Active",
    dataIndex: "Active",
    sorter: (a: { Active: number }, b: { Active: number }) =>
      a.Active - b.Active,
    sortDirections: ["ascend", "descend"],
    filters: activeStatus,
    onFilter: (value: any, record: { Active: boolean | any[] }) =>
      record.Active === value,
    render: (Active: boolean): ReactNode => {
      const status =
        Active === true ? "Active" : Active === false ? "Inactive" : "NA";
      return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
    },
    width: 90,
    align: "center",
  };
  let columns = insertAt(
    FRAME_COLLECTION_LISTING_COLUMN,
    2,
    0,
    frameTypeColumns
  );
  columns = insertAt(columns, 4, 0, vendorColumns);
  columns = insertAt(columns, 5, 0, collectionColumns);
  columns = insertAt(columns, 9, 0, activeColumn);
  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    align: "center",
    fixed: "right",
    width: 100,
    render: (_: any, record: any) => {
      return (
        <div className="d-flex w-100">
          <Button
            icon={<EditOutlined />}
            children={undefined}
            onClick={() => editDrawer(record)}
          ></Button>
          <Button
            style={{ marginLeft: "10px" }}
            icon={<DeleteOutlined />}
            onClick={() => deleteModal(record?.AssortmentId)}
            children={undefined}
            danger={true}
          ></Button>
          <Tooltip placement="top" title="Clone">
            <Button
              style={{ marginLeft: "10px" }}
              icon={<CopyOutlined />}
              onClick={() => editDrawer(record, "Clone")}
              children={undefined}
            ></Button>
          </Tooltip>
        </div>
      );
    },
  };
  columns = insertAt(columns, 14, 0, actionColumn);
  const skuActionColumn = {
    title: "action",
    dataIndex: "operation",
    fixed: "right",
    width: 15,
    render: (_: any, record: any) => {
      return (
        <div className="d-flex w-100">
          <Button
            style={{ marginLeft: "20px" }}
            icon={<DeleteOutlined />}
            onClick={() => deleteModal(record?.UPC)}
            children={undefined}
            danger={true}
          ></Button>
        </div>
      );
    },
  };

  let skuColumns = insertAt(ASSORTMENT_SKU_COLUMN, 7, 0, skuActionColumn);
  return { columns, skuColumns };
}
