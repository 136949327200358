import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import { VENDOR_COLLECTION_LISTING_COLUMN } from "src/util/columns";
import Button from "src/components/common/Button/Button";
import { EditOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import ColorTag from "src/components/common/ColorTag/ColorTag";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any
) {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const vendors: any | null | undefined = [];
  const collection: any | null | undefined = [];
  const dcReplen: any | null | undefined = [];
  data?.map((row: any) => {
    vendors.push({ text: row.VendorName, value: row.VendorName });
    collection.push({ text: row.CollectionName, value: row.CollectionName });
    if (row.DCReplen === true) {
      dcReplen.push({ text: "Yes", value: row.DCReplen });
    } else if (row.DCReplen === false) {
      dcReplen.push({ text: "No", value: row.DCReplen });
    } else {
      dcReplen.push({ text: "NA", value: row.DCReplen });
    }
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  let collectionName = collection.filter(
    (ele: any, ind: any) =>
      ind ===
      collection.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let dcReplenStatus = dcReplen.filter(
    (ele: any, ind: any) =>
      ind === dcReplen.findIndex((elem: any) => elem.text === ele.text)
  );
  const vendorColumns = {
    title: "Vendor Name",
    dataIndex: "VendorName",
    sorter: (a: { VendorName: string }, b: { VendorName: any }) =>
      a.VendorName?.localeCompare(b.VendorName),
    sortDirections: ["ascend", "descend"],
    filters: vendorName?.sort((a: any, b: any) => {
      return a.text.localeCompare(b.text);
    }),
    onFilter: (value: any, record: { VendorName: string | any[] }) =>
      record.VendorName !== null && record.VendorName.indexOf(value) === 0,
    filterSearch: true,
    width: 120,
    align: "center",
    render: (text: string) => {
      return text ? text : "";
    },
  };
  const collectionColumns = {
    title: "Collection Name",
    dataIndex: "CollectionName",
    sorter: (a: { CollectionName: string }, b: { CollectionName: any }) =>
      a.CollectionName?.localeCompare(b.CollectionName),
    sortDirections: ["ascend", "descend"],
    filters: collectionName?.sort((a: any, b: any) => {
      return a.text.localeCompare(b.text);
    }),
    onFilter: (value: any, record: { CollectionName: string | any[] }) =>
      record.CollectionName !== null &&
      record.CollectionName.indexOf(value) === 0,
    filterSearch: true,
    width: 120,
    align: "center",
    render: (text: string) => {
      return text ? text : "";
    },
  };
  const dcReplenColumn = {
    title: "DC Replenishment",
    dataIndex: "DCReplen",
    sorter: (a: { DCReplen: number }, b: { DCReplen: number }) =>
      a.DCReplen - b.DCReplen,
    sortDirections: ["ascend", "descend"],
    filters: dcReplenStatus,
    onFilter: (value: any, record: { DCReplen: boolean | any[] }) =>
      record.DCReplen === value,
    render: (DCReplen: boolean): ReactNode => {
      const status =
        DCReplen === true ? "Yes" : DCReplen === false ? "No" : "NA";
      return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
    },
    width: 90,
    align: "center",
  };
  let columns = insertAt(VENDOR_COLLECTION_LISTING_COLUMN, 3, 0, vendorColumns);
  columns = insertAt(columns, 4, 0, collectionColumns);
  columns = insertAt(columns, 6, 0, dcReplenColumn);
  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    /// align: "center",
    width: 60,
    render: (_: any, record: any) => {
      return (
        <div className="d-flex w-100">
          <Button
            icon={<EditOutlined />}
            children={undefined}
            onClick={() => editDrawer(record)}
          ></Button>
        </div>
      );
    },
  };
  columns = insertAt(columns, 7, 0, actionColumn);

  return { columns };
}
